.works {
  &List {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3em;
    margin-bottom: 3em;
    margin-top: 3em;

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &Item {
  }
}
