.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &Smile {
    font-size: 5em;
    white-space: nowrap;
  }
}
