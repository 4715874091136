.layout {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 90%;
  }

  &Main {
    flex: 1;
  }
}
