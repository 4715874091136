@import '../../../assets/scss/init/vars';

.works {
  &Heading {
    margin-bottom: 24px;
    font-size: 2em;
    font-weight: bold;
    color: $color-black;
  }
}
