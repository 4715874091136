@import '../../assets/scss/init/vars';

.music {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &Heading {
    margin-bottom: 24px;
    font-size: 2em;
    font-weight: bold;
    color: $color-black;

    &Icon {
      width: 24px;
      height: 24px;
      transform: translateY(2px);
    }
  }

  &Explanation {
    position: relative;
    margin-bottom: 2em;
    font-size: 1.25em;
    line-height: 1.4;

    p + p {
      margin-top: 0.75em;
    }
  }

  &Loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    &Icon {
      width: 150px;
      height: 150px;
      animation: spinVinyl 5s infinite;
    }
  }

  &List {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &Item {
    animation: fadeIn 0.5s linear;
    animation-fill-mode: both;

    &Picture {
      position: relative;
      display: flex;
      overflow: hidden;
      aspect-ratio: 1 / 1;

      img {
        width: 100%;
        vertical-align: middle;
        aspect-ratio: 1 / 1;
      }
    }

    &Title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1em;
      transform: translateY(-100%);
      transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.75) 25%,
        rgba(0, 0, 0, 0.5) 50%,
        rgba(0, 0, 0, 0.25) 75%,
        rgba(0, 0, 0, 0.15) 85%,
        rgba(0, 0, 0, 0) 100%
      );
      color: #ffff;

      &Artist {
        margin-bottom: 0.5em;
        font-size: 0.75em;
        font-weight: bold;

        @media (min-width: 1024px) {
          font-size: 1.2em;
        }
      }

      &Album {
        font-size: 0.65em;

        @media (min-width: 1024px) {
          font-size: 1em;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        .musicItemTitle {
          transform: translateY(0);
          transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }

    &Dummy {
      grid-column: 2 / 4;

      @media (min-width: 768px) {
        grid-column: 3 / 5;
      }

      @media (min-width: 1024px) {
        display: none;
      }

      &Link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border: 1px solid $color-light-gray;
        font-weight: bold;
        color: $color-gray;

        @media (hover: hover) {
          &:hover {
            border: 1px solid $color-gray;
          }
        }
      }
    }
  }
}

@keyframes spinVinyl {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@for $i from 1 through 1000 {
  .musicItem:nth-child(#{$i}) {
    animation-delay: 0.1s * $i;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
