html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color-blue;
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: rgba(153, 0, 153, 0.3);
  transition: all 200ms ease-in-out;

  @media (hover: hover) {
    &:hover {
      border-color: transparent;
    }
  }
}

em {
  font-style: italic;
}
