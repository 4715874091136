@import '../../../assets/scss/init/vars';

.history {
  display: block;
  width: 100%;
  max-width: 720px;
  margin: 0 auto 96px;

  &Item {
    &Picture {
      width: 100%;
      aspect-ratio: 1 / 1;
    }
  }

  &Years {
    &List {
      display: flex;
      justify-content: space-between;
    }

    &Item {
    }

    &Button {
      padding: 8px 0;
      border: none;
      background: none;
      cursor: pointer;
      color: $color-blue;

      &Text {
        border-bottom: 1px solid;
        border-color: rgba(153, 0, 153, 0.3);
        transition: all 200ms ease-in-out;
      }

      @media (hover: hover) {
        &:hover {
          .historyYearsButtonText {
            border-color: transparent;
          }
        }
      }

      &Active {
        font-weight: bold;
        color: $color-black;
        cursor: text;

        .historyYearsButtonText {
          border-color: transparent;
        }
      }
    }
  }
}
