@import '../../../assets/scss/init/vars';

.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;

  &Image {
    width: 100%;
    max-width: 150px;
    margin-bottom: 16px;
    transition: all 200ms linear;
  }

  &Title {
    text-align: center;
    font-size: 0.875em;
    line-height: 1.5;
    color: $color-black;

    span {
      border-bottom: 1px solid;
      border-color: rgba(153, 0, 153, 0.3);
      transition: all 200ms ease-in-out;
      color: $color-blue;
    }
  }

  &:hover {
    .thumbnailImage {
      transform: translateY(-5%);
    }

    .thumbnailTitle span {
      border-color: transparent;
    }
  }
}
